import axios from "axios";

const PATH = "https://app.visiongazon.com/";

const CALCULATOR_BACKEND = process.env.REACT_APP_CALCULATOR_BACKEND;

function getAuthorizationHeader(key, secret) {
  return `Custom ${key}:${secret}`;
}

async function makeRequest(endpoint, method = "GET", data = null) {
  const key = process.env.REACT_APP_API_KEY;
  const secret = process.env.REACT_APP_API_SECRET;

  const headers = {
    Authorization: getAuthorizationHeader(key, secret),
    "Content-Type": "application/json",
  };

  const url = `${PATH}${endpoint}`;

  try {
    const response = await axios({ url, method, headers, data });
    return response.data;
  } catch (error) {
    console.error(`Failed to make request to ${endpoint}:`, error);
  }
}

export function getServices() {
  return makeRequest("api/getServices");
}

export function getCities() {
  return makeRequest("api/getCities");
}

export function createCustomerAndContract(data) {
  return makeRequest("api/createCustomerAndContract", "POST", data);
}

export async function sendContactToGHL(data) {
  console.log("Sending contact to GHL...", data);
  const url = `${CALCULATOR_BACKEND}/addToGHL`; 

  try {
    const response = await axios.post(url, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    console.log("Contact added successfully to GHL:", response.data.message);
    return response.data;
  } catch (error) {
    console.error("Failed to send contact to GHL:", error);
  }
}
