export const SCHEDULE_ITEMS = [
  {
    topText: "Spring",
    title: "1st visit",
    dateText: "Before May 31st",
    inModalSliceIndex: 6,
    services: [
      "Fertilizer (spring formula)",
      "Mycorrhizae treatment",
      "Lime treatment",
      "Large leaf weed control",
      "Crystal Green",
      "White grub check",
      "Crabgrass preventative treatment",
    ],
  },
  {
    topText: "Summer",
    title: "2nd visit",
    dateText: "Before July 10th",
    inModalSliceIndex: 5,
    services: [
      "Fertiziler (summer formula)",
      "Mycorrhizae treatment",
      "Lime treatment",
      "Large leaf weed control",
      "Crystal Green",
      "Ant treatment",
      "Spider treatment",
      "Chinch bug treatment",
      "White grub treatment"
    ],
  },
  {
    topText: "Summer",
    title: "3rd visit",
    dateText: "Before August 15th",
    inModalSliceIndex: 5,
    services: [
      "Fertiziler (summer formula)",
      "Mycorrhizae treatment",
      "Lime treatment",
      "Large leaf weed control",
      "Crystal Green",
    ],
  },
  {
    topText: "Fall",
    title: "4th visit",
    inModalSliceIndex: 6,
    dateText: "Before October 15th",
    services: [
      "Fertiziler (fall formula)",
      "Mycorrhizae treatment",
      "Lime treatment",
      "Large leaf weed control",
      "Crystal Green",
      "White grub check",
    ],
  },
  {
    topText: "SPRING VISIT",
    title: "Additional Services",
    services: [
      "Spring Aeration",
      "Spring Aeration and Seeding",
      "Spring top soil and overseeding",
    ],
    isLarge: true,
  },
  {
    topText: "FALL VISIT",
    title: "Additional Services",
    services: [
      "Fall Aeration",
      "Fall Aeration and Seeding",
      "Fall top soil and overseeding",
    ],
    isLarge: true,
  },
];
