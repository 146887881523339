import { useRouteError } from "react-router-dom";
import { useTranslation } from "react-i18next";

import styles from "../styles/ErrorPage.module.scss";

const ErrorPage = () => {
  const { t } = useTranslation();
  const error = useRouteError();
  console.error(error);

  return (
    <div id="error-page" className={styles.container}>
      <h1>Oops!</h1>
      <p>{t("Sorry, an unexpected error has occurred.")}</p>
      <p>
        <i>{error.statusText || error.message}</i>
      </p>
    </div>
  );
};

export default ErrorPage;
