import { useEffect, useState } from "react";
import { sendContactToGHL } from "../middleware/api";

const CALCULATOR_BACKEND = process.env.REACT_APP_CALCULATOR_BACKEND;

const useDetectPageLeave = (
  data,
  contactWasSentToGHL,
  setContactWasSentToGHL,
  inactivityTime = 600000
) => {
  const [isActive, setIsActive] = useState(true);

  useEffect(() => {
    let inactivityTimer;

    const resetTimer = async () => {
      clearTimeout(inactivityTimer);
      setIsActive(true);
      inactivityTimer = setTimeout(async () => {
        if (!contactWasSentToGHL) {
          try {
            await sendContactToGHL(data);
            setContactWasSentToGHL(true);
            setIsActive(false);
          } catch (error) {
            console.error("Failed to send contact to GHL:", error);
            setIsActive(false);
          }
        }
      }, inactivityTime);
    };

    const handleBeforeUnload = async (e) => {
      e.preventDefault();
      e.returnValue = "";
      
      const beaconData = new Blob([JSON.stringify(data)], {
        type: "application/json",
      });

      navigator.sendBeacon(
        "https://vision-gazon-calculator-backend.fly.dev/addToGHL",
        beaconData
      );

      await sendContactToGHL(data);
    };

    const eventListeners = [
      "mousemove",
      "keypress",
      "touchstart",
      "click",
      "scroll",
    ];
    eventListeners.forEach((event) =>
      document.addEventListener(event, resetTimer)
    );

    window.addEventListener("beforeunload", handleBeforeUnload);
    window.onload = resetTimer;

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      eventListeners.forEach((event) =>
        document.removeEventListener(event, resetTimer)
      );
      clearTimeout(inactivityTimer);
    };
  }, [data, contactWasSentToGHL, inactivityTime]);

  return isActive;
};

export default useDetectPageLeave;
