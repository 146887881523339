import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import LOGO_LIGHT from "../assets/logo-white.svg";
import FB_ICON from "../assets/fb-icon.png";

import styles from "../styles/Footer.module.scss";

const Footer = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <footer className={styles.footer}>
      <div className={styles.footer__top}>
        <div className={styles.footer__topLeft}>
          <div className={styles.logo}>
            <img
              src={LOGO_LIGHT}
              alt="Vision Gazon's Logo"
              onClick={() =>
                (window.location.href = "https://visiongazon.com/")
              }
              style={{cursor: "pointer"}}
            />
          </div>
          <span className={styles.largeSeparator} />
          <div className={styles.socialMedia}>
            <span className={styles.socialMedia__followUs}>
              {t("Follow Us")}:
            </span>
            <a href="https://www.facebook.com/visiongazon/">
              <img src={FB_ICON} alt="Facebook" />
            </a>
          </div>
        </div>

        <div className={styles.footer__topRight}>
          <nav className={styles.navigation}>
            <h3>{t("Quick Links")}</h3>
            <a href="https://visiongazon.com/">{t("Home")}</a>
            <a href="https://visiongazon.com/services">
              {t("Services")}
            </a>
            <a href="https://visiongazon.com/about-us">{t("About")}</a>
            <a href="#" onClick={() => navigate("/")}>
              {t("Online Quote")}
            </a>
            <a href="https://visiongazon.com/contact">{t("Contact")}</a>
          </nav>
          <div className={styles.contactInfo}>
            <h3>{t("Contacts")}</h3>
            <a href="mailto:info@visiongazon.com">info@visiongazon.com</a>
            <a href="tel:+1 (514) 332-3720">(514) 332-3720</a>
            <a href="https://www.google.com/maps?gs_lcrp=EgZjaHJvbWUyBggAEEUYOTIKCAEQABiABBiiBDIKCAIQABiABBiiBNIBBzMzMGowajSoAgCwAgA&q=3277+Boul.+Pitfield,+Saint-Laurent,+QC+H4S+1H3&um=1&ie=UTF-8&sa=X&ved=2ahUKEwjBhcTRgcyCAxXTFVkFHZABB3EQ_AUoAXoECAEQAw">
              3277 Boul. Pitfiels, Saint-Laurent, QC H4S 1H3
            </a>
          </div>
        </div>
      </div>

      <div className={styles.copyright}>
        <span>© 2023 Copyright Reserved</span>
        <span className={styles.copyright__separator}></span>
        <a href="https://visiongazon.com/privacy-policy">
          Privacy Policy
        </a>
        <a href="https://visiongazon.com/terms-service">
          Terms of Service
        </a>
        <span className={styles.copyright__separator}></span>
        <a href="https://www.brandvm.com/">
          Designed by Brand Vision Marketing
        </a>
      </div>
    </footer>
  );
};

export default Footer;
