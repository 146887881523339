import React, { useEffect } from "react";

import styles from "../styles/Modal.module.scss";

const Modal = ({ isOpen, onClose, children, showCloseButton = true }) => {
  const handleOverlayClick = (e) => {
    if (
      e.target instanceof Element &&
      e.target.classList.contains(styles["modal-overlay"])
    ) {
      onClose();
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("body-no-scroll");
    } else {
      document.body.classList.remove("body-no-scroll");
    }
    return () => {
      document.body.classList.remove("body-no-scroll");
    };
  }, [isOpen]);

  return !isOpen ? null : (
    <div className={styles["modal-overlay"]} onClick={handleOverlayClick}>
      <div className={styles["modal-content"]}>
        {showCloseButton && (
          <button className={styles["close-button"]} onClick={onClose}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="48"
              height="48"
              viewBox="0 0 48 48"
              fill="none"
            >
              <rect width="48" height="48" rx="24" fill="#F1F1F1" />
              <path
                d="M24 22.4445L29.4445 17L31 18.5556L25.5556 24L31 29.4445L29.4445 31L24 25.5556L18.5556 31L17 29.4445L22.4445 24L17 18.5556L18.5556 17L24 22.4445Z"
                fill="black"
              />
            </svg>
          </button>
        )}
        {children}
      </div>
    </div>
  );
};

export default Modal;
