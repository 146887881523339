import React, { createContext, useState, useRef } from "react";
import { useMediaQuery } from "react-responsive";

export const AppDataContext = createContext();

export const AppDataProvider = ({ children }) => {
  const [servicesCost, setServicesCost] = useState(0);
  const [selectedServices, setSelectedServices] = useState(["The Essential Plan"]);
  const [selectedSchedule, setSelectedSchedule] = useState("");
  const [selectedAddress, setSelectedAddress] = useState("");
  const [long, setLong] = useState(0);
  const [lat, setLat] = useState(0);
  const [step, setStep] = useState(1);
  const [maxStep, setMaxStep] = useState(1);
  const [area, setArea] = useState(0);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [specialInstructions, setSpecialInstructions] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [cardHolder, setCardHolder] = useState("");
  const [cardExpiration, setCardExpiration] = useState("");
  const [cardCVC, setCardCVC] = useState("");
  const [cardType, setCardType] = useState("");
  const [isPromoDisabled, setIsPromoDisabled] = useState(false);
  const [promoCode, setPromoCode] = useState("");
  const [email, setEmail] = useState("");
  const [coreSoilVisits, setCoreSoilVisits] = useState([]);
  const [selectedBillingAddress, setSelectedBillingAddress] = useState("");
  const [addressObj, setAddressObj] = useState({});
  const [billingAddressObj, setBillingAddressObj] = useState({});
  const [couponWasApplied, setCouponWasApplied] = useState(false);
  const [addressPageIsInstructionModalOpen, setAddressPageIsInstructionModalOpen] = useState(true);
  const [contactWasSentToGHL, setContactWasSentToGHL] = useState(false);


  const isMobile = useMediaQuery({ query: "(max-width: 1010px)" });
  const isLG = useMediaQuery({ query: "(max-width: 1200px)" });

  const menuBarRef = useRef(null);
  const mainContentRef = useRef(null);

  return (
    <AppDataContext.Provider
      value={{
        servicesCost,
        setServicesCost,
        selectedServices,
        setSelectedServices,
        selectedSchedule,
        setSelectedSchedule,
        selectedAddress,
        setSelectedAddress,
        long,
        setLong,
        lat,
        setLat,
        step,
        setStep,
        area,
        setArea,
        name,
        setName,
        phone,
        setPhone,
        companyName,
        setCompanyName,
        specialInstructions,
        setSpecialInstructions,
        cardNumber,
        setCardNumber,
        cardHolder,
        setCardHolder,
        cardExpiration,
        setCardExpiration,
        cardCVC,
        setCardCVC,
        isPromoDisabled,
        setIsPromoDisabled,
        promoCode,
        setPromoCode,
        email,
        setEmail,
        isMobile,
        menuBarRef,
        mainContentRef,
        isLG,
        coreSoilVisits,
        setCoreSoilVisits,
        cardType,
        setCardType,
        maxStep,
        setMaxStep,
        selectedBillingAddress,
        setSelectedBillingAddress,
        addressObj,
        setAddressObj,
        billingAddressObj,
        setBillingAddressObj,
        couponWasApplied,
        setCouponWasApplied,
        addressPageIsInstructionModalOpen,
        setAddressPageIsInstructionModalOpen,
        contactWasSentToGHL,
        setContactWasSentToGHL
      }}
    >
      {children}
    </AppDataContext.Provider>
  );
};
