import React, { useContext, useState, useEffect } from "react";
import { AppDataContext } from "../contexts/AppData.context";
import { useTranslation } from "react-i18next";
import Modal from "../components/Modal";

import styles from "../styles/PaymentSchedule.module.scss";

const RADIO_BUTTONS = [
  {
    name: "3 Year Contract - Annual",
    smallName: "3 Year - Annual",
    hasDiscount: true,
    unit: "$/year",
  },
  {
    name: "3 Year Contract - Monthly",
    smallName: "3 Year - Monthly",
    hasDiscount: true,
    unit: "$/month",
  },
  {
    name: "1 Year Contract - Annual",
    smallName: "1 Year - Annual",
    hasDiscount: false,
    unit: "$/year",
  },
  {
    name: "1 Year Contract - Monthly",
    smallName: "1 Year - Monthly",
    hasDiscount: false,
    unit: "$/month",
  },
];

const PaymentSchedule = ({ price }) => {
  const {
    isLG,
    couponWasApplied,
    servicesCost,
    selectedSchedule,
    setSelectedSchedule,
  } = useContext(AppDataContext);
  const [paymentDetailsModalOpen, setPaymentDetailsModalOpen] = useState(false);
  const [paymentDates, setPaymentDates] = useState([]);
  const { t } = useTranslation();

  const getDiscount = () => {
    var discount = 0;
    if (selectedSchedule.includes("3 Year")) discount += 5;
    if (couponWasApplied) discount += 20;
    return discount;
  };

  const totalDiscount = (100 - getDiscount()) / 100;

  const calculatePaymentAmount = () => {
    let totalCost = servicesCost * totalDiscount * 1.14975;
    if (selectedSchedule.includes("3 Year")) totalCost *= 3;
    const numPayments = paymentDates.length;
    return (totalCost / numPayments).toFixed(2);
  };

  const getNumMonths = (name) => {
    if (name.includes("Monthly")) {
      const currentMonth = new Date().getMonth() + 1;
      const february = 2;
      const september = 9;

      const is3Year = name.includes("3 Year");

      if (currentMonth <= february) {
        return is3Year ? " (24x)" : ` (8x)`;
      } else if (currentMonth <= september) {
        return is3Year
          ? ` (${september - currentMonth + 1 + 16}x)`
          : ` (${september - currentMonth + 1}x)`;
      } else {
        return is3Year ? " (24x)" : ` (8x)`;
      }
    }
    return "";
  };

  const getNumMonthsNum = (name) => {
    if (name.includes("1 Year") && name.includes("Monthly")) {
      const currentMonth = new Date().getMonth() + 1;
      const february = 2;
      const september = 9;

      if (currentMonth <= february) {
        return september - february + 1;
      } else if (currentMonth <= september) {
        return september - currentMonth + 1;
      } else {
        return 8;
      }
    }
    return 8;
  };

  const numMonths = getNumMonthsNum("1 Year - Monthly");

  const calculateDiscount = (price, hasDiscount, isMonthly) => {
    let newPrice;

    if (hasDiscount) {
      if (couponWasApplied) {
        newPrice = (price * 0.75).toFixed(2);
      } else {
        newPrice = (price * 0.95).toFixed(2);
      }
    } else {
      if (couponWasApplied) {
        newPrice = (price * 0.8).toFixed(2);
      } else {
        newPrice = price.toFixed(2);
      }
    }

    const numMonths = isMonthly ? getNumMonthsNum("1 Year - Monthly") : 1;

    return (newPrice / numMonths).toFixed(2);
  };

  const handlePaymentModal = (e) => {
    e.preventDefault();

    if (!selectedSchedule) {
      return;
    } else {
      setPaymentDetailsModalOpen(true);
    }
  };

  const generatePaymentDates = (selectedSchedule) => {
    const dates = [];
    let currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1;
    const february = 2;
    const september = 9;

    // If it's after September or before February, set the first payment to be in February of the next year
    // if (currentMonth > september || currentMonth < february) {
    //   currentDate = new Date(currentDate.getFullYear() + 1, february - 1, 1);
    // }

    if (selectedSchedule === "1 Year Contract - Annual") {
      // 1 year annual: all the payment collected today
      dates.push(currentDate);
    } else if (selectedSchedule === "1 Year Contract - Monthly") {
      // 1 year monthly: only payments in this year, number of months calculated based on the getNumMonths function
      const numMonths = getNumMonthsNum(selectedSchedule);
      for (let i = 0; i < numMonths; i++) {
        const paymentDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() + i,
          currentDate.getDate()
        );
        dates.push(paymentDate);
      }
    } else if (selectedSchedule === "3 Year Contract - Annual") {
      // 3 year annual: 3 payments, starting today, each one year apart
      for (let i = 0; i < 3; i++) {
        const paymentDate = new Date(
          currentDate.getFullYear() + i,
          currentDate.getMonth(),
          currentDate.getDate()
        );
        dates.push(paymentDate);
      }
    } else if (selectedSchedule === "3 Year Contract - Monthly") {
      // 3 year monthly: for the first year, assume scenario 2. after first year the user will be charged from feburary to september (total of 8 payments)
      const numMonthsFirstYear = getNumMonthsNum(selectedSchedule);
      for (let i = 0; i < numMonthsFirstYear; i++) {
        const paymentDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() + i,
          currentDate.getDate()
        );
        dates.push(paymentDate);
      }
      for (let i = 1; i < 3; i++) {
        for (let j = 2; j <= 9; j++) {
          const paymentDate = new Date(
            currentDate.getFullYear() + i,
            j - 1,
            currentDate.getDate()
          );
          dates.push(paymentDate);
        }
      }
    }

    setPaymentDates(dates);
  };

  useEffect(() => {
    if (selectedSchedule) {
      generatePaymentDates(selectedSchedule);
    }
  }, [selectedSchedule]);

  return (
    <>
      <div
        className={
          !selectedSchedule
            ? styles.smallPill
            : `${styles.smallPill} ${styles["smallPill__view"]}`
        }
        style={{ marginTop: "27px" }}
      >
        <span
          style={{
            color: selectedSchedule ? "#fff" : "#65ae6b",
            display: "flex",
            alignItems: "center",
            gap: "5px",
          }}
          onClick={handlePaymentModal}
        >
          {selectedSchedule && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="15"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M9 15H11V9H9V15ZM10 7C10.2833 7 10.521 6.904 10.713 6.712C10.905 6.52 11.0007 6.28267 11 6C11 5.71667 10.904 5.479 10.712 5.287C10.52 5.095 10.2827 4.99933 10 5C9.71667 5 9.479 5.096 9.287 5.288C9.095 5.48 8.99933 5.71733 9 6C9 6.28333 9.096 6.521 9.288 6.713C9.48 6.905 9.71733 7.00067 10 7ZM10 20C8.61667 20 7.31667 19.7373 6.1 19.212C4.88333 18.6867 3.825 17.9743 2.925 17.075C2.025 16.175 1.31267 15.1167 0.788 13.9C0.263333 12.6833 0.000666667 11.3833 0 10C0 8.61667 0.262667 7.31667 0.788 6.1C1.31333 4.88333 2.02567 3.825 2.925 2.925C3.825 2.025 4.88333 1.31267 6.1 0.788C7.31667 0.263333 8.61667 0.000666667 10 0C11.3833 0 12.6833 0.262667 13.9 0.788C15.1167 1.31333 16.175 2.02567 17.075 2.925C17.975 3.825 18.6877 4.88333 19.213 6.1C19.7383 7.31667 20.0007 8.61667 20 10C20 11.3833 19.7373 12.6833 19.212 13.9C18.6867 15.1167 17.9743 16.175 17.075 17.075C16.175 17.975 15.1167 18.6877 13.9 19.213C12.6833 19.7383 11.3833 20.0007 10 20ZM10 18C12.2333 18 14.125 17.225 15.675 15.675C17.225 14.125 18 12.2333 18 10C18 7.76667 17.225 5.875 15.675 4.325C14.125 2.775 12.2333 2 10 2C7.76667 2 5.875 2.775 4.325 4.325C2.775 5.875 2 7.76667 2 10C2 12.2333 2.775 14.125 4.325 15.675C5.875 17.225 7.76667 18 10 18Z"
                fill="white"
              />
            </svg>
          )}

          {!selectedSchedule
            ? t("Choose payment schedule")
            : t("view payment schedule")}
        </span>
      </div>
      {RADIO_BUTTONS.map((item, index) => (
        <div className={styles.inputGroup} key={`payment-schedule-${index}`}>
          <div className={styles.inputGroup__side}>
            <input
              type="radio"
              id={item.name}
              name={`payment-schedule-${index}`}
              value={item.name}
              className={styles.inputGroup__radio}
              onChange={() => setSelectedSchedule(item.name)}
              checked={selectedSchedule === item.name}
            />
            <label
              htmlFor={item.name}
              className={styles.inputGroup__lbl}
              style={{ color: item.hasDiscount ? "#57ae61" : "#094C36" }}
            >
              {!isLG ? t(item.name) : t(item.smallName)}
              {getNumMonths(item.name)}
              {item.hasDiscount && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                  className={styles.icon}
                >
                  <path
                    d="M9.67002 1.50586C10.9587 1.50586 12.0033 2.55053 12.0033 3.83919C12.0033 4.19733 11.9227 4.53663 11.7785 4.83989L14.0033 4.83919C14.3715 4.83919 14.67 5.13767 14.67 5.50586V8.17251C14.67 8.54071 14.3715 8.83917 14.0033 8.83917H13.3367V14.1725C13.3367 14.5407 13.0382 14.8392 12.67 14.8392H3.33667C2.96848 14.8392 2.67 14.5407 2.67 14.1725V8.83917H2.00334C1.63515 8.83917 1.33667 8.54071 1.33667 8.17251V5.50586C1.33667 5.13767 1.63515 4.83919 2.00334 4.83919L4.22821 4.83989C4.08402 4.53663 4.00334 4.19733 4.00334 3.83919C4.00334 2.55053 5.04801 1.50586 6.33667 1.50586C6.99002 1.50586 7.58062 1.77437 8.00415 2.20705C8.42608 1.77437 9.01668 1.50586 9.67002 1.50586ZM12.0033 8.83917H4.00334V13.5058H12.0033V8.83917ZM13.3367 6.17253H2.67V7.50584H13.3367V6.17253ZM6.33667 2.83919C5.78439 2.83919 5.33667 3.28691 5.33667 3.83919C5.33667 4.35899 5.73326 4.78616 6.24036 4.83461L6.33667 4.83919H7.33668V3.83919C7.33668 3.31939 6.94008 2.89223 6.43298 2.84377L6.33667 2.83919ZM9.67002 2.83919L9.57368 2.84377C9.09828 2.8892 8.72002 3.26748 8.67455 3.74289L8.67002 3.83919V4.83919H9.67002L9.76628 4.83461C10.2734 4.78616 10.67 4.35899 10.67 3.83919C10.67 3.31939 10.2734 2.89223 9.76628 2.84377L9.67002 2.83919Z"
                    fill="#65AE6B"
                  />
                </svg>
              )}
            </label>
          </div>
          <div className={styles.inputGroup__side}>
            {item.hasDiscount ? (
              <div className={styles.inputGroup__priceGroup}>
                <span className={styles.originalPrice}>{`${
                  index % 2
                    ? calculateDiscount(price, false, true)
                    : calculateDiscount(price, false, false)
                } $`}</span>
                <span
                  className={styles.inputGroup__price}
                  style={{
                    color: item.hasDiscount ? "#57ae61" : "#094C36",
                    fontWeight: 600,
                  }}
                >
                  {`${
                    index % 2 === 1
                      ? calculateDiscount(price, true, true)
                      : calculateDiscount(price, true, false)
                  } ${t(item.unit)} + tx`}
                </span>
              </div>
            ) : (
              <span className={styles.inputGroup__price}>
                {`${
                  index % 2
                    ? calculateDiscount(price, false, true)
                    : calculateDiscount(price, false, false)
                } ${t(item.unit)} + tx`}
              </span>
            )}
          </div>
        </div>
      ))}

      <div className={styles.disclaimer}>
        {t(
          "* Monthly payments are calculated and processed between February and September"
        )}
      </div>

      {paymentDetailsModalOpen && (
        <Modal
          isOpen={paymentDetailsModalOpen}
          onClose={() => setPaymentDetailsModalOpen(false)}
          showCloseButton={false}
        >
          <div className={styles.modalContainer}>
            <h3>{t("Information")}</h3>
            <ul>
              {paymentDates.map((date, index) => (
                <li key={index}>
                  {date.toLocaleDateString(
                    localStorage.getItem("lang") === "fr" ? "fr-CA" : "en-US",
                    {
                      year: "numeric",
                      month: "short",
                      day: "numeric",
                    }
                  )}
                  : {calculatePaymentAmount()}$
                </li>
              ))}
            </ul>
          </div>
        </Modal>
      )}
    </>
  );
};

export default PaymentSchedule;
