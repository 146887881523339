import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AppDataContext } from "../contexts/AppData.context";
import { motion } from "framer-motion";

import { getServiceCost } from "../utils/priceCalculator";

import styles from "../styles/ServiceItem.module.scss";

const ServiceItem = ({
  icon,
  title,
  description,
  options,
  bgColor,
  onInfoBtnClick,
  onServiceBtnClick,
  onEssentialInfoBtnClick,
}) => {
  const handleInfoClick =
    title === "The Essential Plan" ? onEssentialInfoBtnClick : onInfoBtnClick;

  const { t } = useTranslation();
  const {
    selectedServices,
    isMobile,
    area,
    coreSoilVisits,
    setCoreSoilVisits,
  } = useContext(AppDataContext);

  const [iconSize, setIconSize] = useState({ width: 0, height: 0 });

  const aerationServiceCost = getServiceCost("Core Soil Aeration", area);
  const seedingServiceCost = getServiceCost(
    "Core Soil Aeration + Seeding",
    area
  );

  const isServiceSelected =
    title === "Ant treatment" || title === "Spider treatment"
      ? selectedServices.includes("Ant and Spider treatment") ||
        selectedServices.includes(title)
      : title === "Core Soil Aeration" &&
        (selectedServices.includes("Core Soil Aeration + Seeding") ||
          selectedServices.includes(title))
      ? true
      : selectedServices.includes(title);

  const isTopSoil = title === "Top soil and overseeding";

  const handleAddSeeding = () => {
    onServiceBtnClick("Core Soil Aeration + Seeding");
  };

  useEffect(() => {
    const svgIcon = document.querySelector('[id="key-svg-icon"]');
    if (svgIcon) {
      const { width, height } = svgIcon.getBoundingClientRect();
      setIconSize({ width, height });
    }
  }, []);

  return (
    <div className={styles.serviceItem}>
      <div
        className={styles.serviceItem__service}
        style={{ background: bgColor }}
      >
        {!isMobile && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            className={styles.serviceItem__sideIcon}
            onClick={() => handleInfoClick(title)}
          >
            <path
              d="M9 15H11V9H9V15ZM10 7C10.2833 7 10.521 6.904 10.713 6.712C10.905 6.52 11.0007 6.28267 11 6C11 5.71667 10.904 5.479 10.712 5.287C10.52 5.095 10.2827 4.99933 10 5C9.71667 5 9.479 5.096 9.287 5.288C9.095 5.48 8.99933 5.71733 9 6C9 6.28333 9.096 6.521 9.288 6.713C9.48 6.905 9.71733 7.00067 10 7ZM10 20C8.61667 20 7.31667 19.7373 6.1 19.212C4.88333 18.6867 3.825 17.9743 2.925 17.075C2.025 16.175 1.31267 15.1167 0.788 13.9C0.263333 12.6833 0.000666667 11.3833 0 10C0 8.61667 0.262667 7.31667 0.788 6.1C1.31333 4.88333 2.02567 3.825 2.925 2.925C3.825 2.025 4.88333 1.31267 6.1 0.788C7.31667 0.263333 8.61667 0.000666667 10 0C11.3833 0 12.6833 0.262667 13.9 0.788C15.1167 1.31333 16.175 2.02567 17.075 2.925C17.975 3.825 18.6877 4.88333 19.213 6.1C19.7383 7.31667 20.0007 8.61667 20 10C20 11.3833 19.7373 12.6833 19.212 13.9C18.6867 15.1167 17.9743 16.175 17.075 17.075C16.175 17.975 15.1167 18.6877 13.9 19.213C12.6833 19.7383 11.3833 20.0007 10 20ZM10 18C12.2333 18 14.125 17.225 15.675 15.675C17.225 14.125 18 12.2333 18 10C18 7.76667 17.225 5.875 15.675 4.325C14.125 2.775 12.2333 2 10 2C7.76667 2 5.875 2.775 4.325 4.325C2.775 5.875 2 7.76667 2 10C2 12.2333 2.775 14.125 4.325 15.675C5.875 17.225 7.76667 18 10 18Z"
              fill="white"
            />
          </svg>
        )}

        {!isMobile && (
          <div
            className={styles.serviceItem__greenSide}
            onClick={() => handleInfoClick(title)}
          ></div>
        )}

        <div className={styles.serviceItem__mini}>
          {!isMobile &&
            (title === "White grub treatment" ? (
              <img
                src={icon}
                alt="White grub treatment"
                style={{ width: iconSize.width, height: iconSize.height }}
              />
            ) : (
              icon
            ))}

          {!isMobile ? (
            <div className={styles.serviceItem__info}>
              <div className={styles.serviceItem__title}>{t(title)}</div>
              <div className={styles.serviceItem__description}>
                {t(description)}
              </div>
            </div>
          ) : (
            <>
              <div className={styles.serviceItem__mobileServiceLeft}>
                {title === "White grub treatment" ? (
                  <img
                    src={icon}
                    alt="White grub treatment"
                    style={{ width: iconSize.width, height: iconSize.height }}
                  />
                ) : (
                  icon
                )}
                <div className={styles.serviceItem__title}>{t(title)}</div>
              </div>
              <button
                className={styles.serviceItem__mobileInfoBtn}
                onClick={() => handleInfoClick(title)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                >
                  <path
                    d="M8.1 13.5H9.9V8.1H8.1V13.5ZM9 6.3C9.255 6.3 9.4689 6.2136 9.6417 6.0408C9.8145 5.868 9.9006 5.6544 9.9 5.4C9.9 5.145 9.8136 4.9311 9.6408 4.7583C9.468 4.5855 9.2544 4.4994 9 4.5C8.745 4.5 8.5311 4.5864 8.3583 4.7592C8.1855 4.932 8.0994 5.1456 8.1 5.4C8.1 5.655 8.1864 5.8689 8.3592 6.0417C8.532 6.2145 8.7456 6.3006 9 6.3ZM9 18C7.755 18 6.585 17.7636 5.49 17.2908C4.395 16.818 3.4425 16.1769 2.6325 15.3675C1.8225 14.5575 1.1814 13.605 0.7092 12.51C0.237 11.415 0.0006 10.245 0 9C0 7.755 0.2364 6.585 0.7092 5.49C1.182 4.395 1.8231 3.4425 2.6325 2.6325C3.4425 1.8225 4.395 1.1814 5.49 0.7092C6.585 0.237 7.755 0.0006 9 0C10.245 0 11.415 0.2364 12.51 0.7092C13.605 1.182 14.5575 1.8231 15.3675 2.6325C16.1775 3.4425 16.8189 4.395 17.2917 5.49C17.7645 6.585 18.0006 7.755 18 9C18 10.245 17.7636 11.415 17.2908 12.51C16.818 13.605 16.1769 14.5575 15.3675 15.3675C14.5575 16.1775 13.605 16.8189 12.51 17.2917C11.415 17.7645 10.245 18.0006 9 18ZM9 16.2C11.01 16.2 12.7125 15.5025 14.1075 14.1075C15.5025 12.7125 16.2 11.01 16.2 9C16.2 6.99 15.5025 5.2875 14.1075 3.8925C12.7125 2.4975 11.01 1.8 9 1.8C6.99 1.8 5.2875 2.4975 3.8925 3.8925C2.4975 5.2875 1.8 6.99 1.8 9C1.8 11.01 2.4975 12.7125 3.8925 14.1075C5.2875 15.5025 6.99 16.2 9 16.2Z"
                    fill="#65AE6B"
                  />
                </svg>
              </button>
            </>
          )}
        </div>

        {isMobile && (
          <div className={styles.serviceItem__description}>
            {t(description)}
          </div>
        )}

        <button
          className={`${styles.serviceItem__btn} ${
            isServiceSelected ? styles["serviceItem__btn--selected"] : ""
          }`}
          onClick={
            title === "Top soil and overseeding"
              ? () => (window.location.href = "tel:+15143323720")
              : () => onServiceBtnClick(title)
          }
        >
          {title === "Top soil and overseeding"
            ? t("Call for Pricing")
            : isServiceSelected
            ? t("Deselect")
            : t("Select Service")}
        </button>
      </div>
      {(isServiceSelected || isTopSoil) && options?.length ? (
        <motion.div className={styles.serviceItem__options}>
          <div className={styles.serviceItem__optionsHeading}>Options</div>
          {options.map((option, index) => (
            <div className={styles.inputGroup} key={`${title}-option-${index}`}>
              <div className={styles.inputGroup__side}>
                {option === "Add Seeding" ? (
                  <button
                    onClick={handleAddSeeding}
                    className={styles.addSeedingBtn}
                  >
                    {selectedServices.includes(
                      "Core Soil Aeration + Seeding"
                    ) ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                      >
                        <path
                          d="M7 13.4L3 9.4L4.4 8L7 10.6L13.6 4L15 5.4L7 13.4Z"
                          fill="#65AE6B"
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                      >
                        <path
                          d="M6 1L6 11"
                          stroke="black"
                          strokeWidth="2"
                          strokeLinecap="round"
                        />
                        <path
                          d="M11 6L1 6"
                          stroke="black"
                          strokeWidth="2"
                          strokeLinecap="round"
                        />
                      </svg>
                    )}

                    <span>{t("Add Seeding")}</span>
                  </button>
                ) : (
                  <>
                    <input
                      type="checkbox"
                      id={`${title}-option-${index}`}
                      name={`payment-schedule-${index}`}
                      value={option}
                      className={styles.inputGroup__radio}
                      checked={coreSoilVisits.includes(option)}
                      onChange={() => {
                        if (coreSoilVisits.includes(option)) {
                          setCoreSoilVisits(
                            coreSoilVisits.filter((visit) => visit !== option)
                          );
                        } else {
                          setCoreSoilVisits([...coreSoilVisits, option]);
                        }
                      }}
                    />
                    <label
                      htmlFor={`${title}-option-${index}`}
                      className={styles.inputGroup__lbl}
                      style={{ color: "#094C36" }}
                    >
                      {t(option)}
                    </label>
                  </>
                )}
              </div>
              <div className={styles.inputGroup__side}>
                {isTopSoil ? (
                  <span className={styles.inputGroup__price}>
                    Pricing available upon request
                  </span>
                ) : (
                  <span className={styles.inputGroup__price}>
                    {option === "Add Seeding"
                      ? (seedingServiceCost - aerationServiceCost).toFixed(2)
                      : aerationServiceCost.toFixed(2)}{" "}
                    $
                  </span>
                )}
              </div>
            </div>
          ))}
        </motion.div>
      ) : null}
    </div>
  );
};

export default ServiceItem;
