import FERTILIZATION_IMG from "../assets/serviceModals/fertilization_img.webp";
import AERATION_IMG from "../assets/serviceModals/aeration_img.webp";
import AERATION_IMG_2 from "../assets/serviceModals/aeration_img_2.jpeg";
import LIMESTONE_IMG from "../assets/serviceModals/limestone_img.webp";
import LIMESTONE_IMG_2 from "../assets/serviceModals/limestone_img_2.jpeg";
import MYCORRHIZAE_IMG from "../assets/serviceModals/mycorrhizae_img.webp";
import CRABGRASS_IMG from "../assets/serviceModals/crabgrass_img.webp";
import CRABGRASS_IMG_2 from "../assets/serviceModals/crabgrass_img_2.webp";
import CRABGRASS_IMG_3 from "../assets/serviceModals/crabgrass_img_3.jpeg";
import CRABGRASS_IMG_4 from "../assets/serviceModals/crabgrass_img_4.jpeg";
import WHITEGRUB_IMG from "../assets/serviceModals/whitegrub_img.webp";
import WHITEGRUB_IMG_2 from "../assets/serviceModals/whitegrub_img_2.jpeg";
import WHITEGRUB_IMG_3 from "../assets/serviceModals/whitegrub_img_3.jpeg";
import ANT_IMG from "../assets/serviceModals/ant_img.webp";
import ANT_IMG_2 from "../assets/serviceModals/ant_img_2.jpeg";
import ANT_IMG_3 from "../assets/serviceModals/ant_img_3.jpeg";
import CHINCHBUG_IMG from "../assets/serviceModals/chinchbug_img.webp";
import CHINCHBUG_IMG_2 from "../assets/serviceModals/chinchbug_img_2.webp";
import CHINCHBUG_IMG_3 from "../assets/serviceModals/chinchbug_img_3.jpeg";
import SPIDER_IMG from "../assets/serviceModals/spider_img.webp";
import SPIDER_IMG_2 from "../assets/serviceModals/spider_img_2.jpeg";
import TOPSOIL_IMG from "../assets/serviceModals/topsoil_img.webp";
import TOPSOIL_IMG_2 from "../assets/serviceModals/topsoil_img_2.jpeg";
import TOPSOIL_IMG_3 from "../assets/serviceModals/topsoil_img_3.png";

export const SERVICE_MODAL_DATA = {
  Fertilization: {
    images: [FERTILIZATION_IMG],
    what: "FERTILIZATION_WHAT",
    why: "FERTILIZATION_WHY",
    how: "FERTILIZATION_HOW",
    recommendations: ["FERTILIZATION_REC_1"],
    tips: ["FERTILIZATION_TIP_1"],
    attentions: [
      "FERTILIZATION_ATTENTION_1",
      "FERTILIZATION_ATTENTION_2",
      "FERTILIZATION_ATTENTION_3",
    ],
  },
  "Core Soil Aeration": {
    images: [AERATION_IMG, AERATION_IMG_2],
    what: "AERATION_WHAT",
    why: "AERATION_WHY",
    how: "AERATION_HOW",
    recommendations: ["AERATION_REC_1"],
    tips: ["AERATION_TIP_1"],
    attentions: [
      "AERATION_ATTENTION_1",
      "AERATION_ATTENTION_2",
      "AERATION_ATTENTION_3",
    ],
  },
  "Limestone application": {
    images: [LIMESTONE_IMG, LIMESTONE_IMG_2],
    what: "LIMESTONE_WHAT",
    why: "LIMESTONE_WHY",
    how: "LIMESTONE_HOW",
    recommendations: ["LIMESTONE_REC_1"],
    tips: ["LIMESTONE_TIP_1"],
    attentions: [
      "LIMESTONE_ATTENTION_1",
      "LIMESTONE_ATTENTION_2",
      "LIMESTONE_ATTENTION_3",
      "LIMESTONE_ATTENTION_4",
    ],
  },
  Mycorrhizae: {
    images: [MYCORRHIZAE_IMG],
    what: "MYCORRHIZAE_WHAT",
    why: "MYCORRHIZAE_WHY",
    how: "MYCORRHIZAE_HOW",
    recommendations: ["MYCORRHIZAE_REC_1"],
    tips: [
      "MYCORRHIZAE_TIP_1",
      "MYCORRHIZAE_TIP_2",
      "MYCORRHIZAE_TIP_3",
      "MYCORRHIZAE_TIP_4",
      "MYCORRHIZAE_TIP_5",
      "MYCORRHIZAE_TIP_6",
    ],
    attentions: [
      "MYCORRHIZAE_ATTENTION_1",
      "MYCORRHIZAE_ATTENTION_2",
      "MYCORRHIZAE_ATTENTION_3",
    ],
  },
  "Crabgrass preventive treatment": {
    images: [CRABGRASS_IMG_2, CRABGRASS_IMG_3, CRABGRASS_IMG_4],
    what: "CRABGRASS_WHAT",
    why: "CRABGRASS_WHY",
    how: "CRABGRASS_HOW",
    recommendations: ["CRABGRASS_REC_1"],
    tips: [
      "CRABGRASS_TIP_1",
      "CRABGRASS_TIP_2",
      "CRABGRASS_TIP_3",
      "CRABGRASS_TIP_4",
      "CRABGRASS_TIP_5",
    ],
    attentions: ["CRABGRASS_ATTENTION_1"],
  },
  "White grub treatment": {
    images: [WHITEGRUB_IMG, WHITEGRUB_IMG_2, WHITEGRUB_IMG_3],
    what: "WHITEGRUB_WHAT",
    why: "WHITEGRUB_WHY",
    how: "WHITEGRUB_HOW",
    recommendations: ["WHITEGRUB_REC_1", "WHITEGRUB_REC_2"],
    tips: ["WHITEGRUB_TIP_1"],
    attentions: [
      "WHITEGRUB_ATTENTION_1",
      "WHITEGRUB_ATTENTION_2",
      "WHITEGRUB_ATTENTION_3",
      "WHITEGRUB_ATTENTION_4",
    ],
  },
  "Ant treatment": {
    images: [ANT_IMG, ANT_IMG_2, ANT_IMG_3],
    what: "ANT_WHAT",
    why: "ANT_WHY",
    how: "ANT_HOW",
    recommendations: ["ANT_REC_1"],
    tips: ["ANT_TIP_1", "ANT_TIP_2", "ANT_TIP_3", "ANT_TIP_4", "ANT_TIP_5"],
    attentions: ["ANT_ATTENTION_1", "ANT_ATTENTION_2", "ANT_ATTENTION_3"],
  },
  "Chinch bug treatment": {
    images: [CHINCHBUG_IMG, CHINCHBUG_IMG_2, CHINCHBUG_IMG_3],
    what: "CHINCHBUG_WHAT",
    why: "CHINCHBUG_WHY",
    how: "CHINCHBUG_HOW",
    recommendations: ["CHINCHBUG_REC_1"],
    tips: ["CHINCHBUG_TIP_1"],
    attentions: [
      "CHINCHBUG_ATTENTION_1",
      "CHINCHBUG_ATTENTION_2",
      "CHINCHBUG_ATTENTION_3",
    ],
  },
  "Spider treatment": {
    images: [SPIDER_IMG, SPIDER_IMG_2],
    what: "SPIDER_WHAT",
    why: "SPIDER_WHY",
    how: "SPIDER_HOW",
    recommendations: ["SPIDER_REC_1"],
    tips: [
      "SPIDER_TIP_1",
      "SPIDER_TIP_2",
      "SPIDER_TIP_3",
      "SPIDER_TIP_4",
      "SPIDER_TIP_5",
      "SPIDER_TIP_6",
      "SPIDER_TIP_7",
      "SPIDER_TIP_8",
    ],
    attentions: ["SPIDER_ATTENTION_1", "SPIDER_ATTENTION_2"],
  },
  "Top soil and overseeding": {
    images: [TOPSOIL_IMG, TOPSOIL_IMG_2, TOPSOIL_IMG_3],
    what: "TOPSOIL_WHAT",
    why: "TOPSOIL_WHY",
    how: "TOPSOIL_HOW",
    recommendations: ["TOPSOIL_REC_1"],
    tips: ["TOPSOIL_TIP_1"],
    attentions: [
      "TOPSOIL_ATTENTION_1",
      "TOPSOIL_ATTENTION_2",
      "TOPSOIL_ATTENTION_3",
    ],
  },
};
