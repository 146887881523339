import React from "react";

import { useTranslation } from "react-i18next";

import { SCHEDULE_ITEMS } from "../constants/SchedulePageConstants";

import Modal from "./Modal";
import ScheduleItem from "./ScheduleItem";

import styles from "../styles/EssentialPlanModal.module.scss";

const EssentialPlanModal = ({ isOpen, onClose }) => {
  const { t } = useTranslation();

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className={styles.container}>
        <div className={styles.container__pill}>{t("OUR ANNUAL PLAN")}</div>
        <div className={styles.container__title}>{t("The Essential Plan")}</div>
        <div className={styles.container__text}>
          {t("ESSENTIAL_PLAN_MODAL_TEXT")}
        </div>
        <div className={styles.container__items}>
          {SCHEDULE_ITEMS.slice(0, 4).map((item, index) => (
            <ScheduleItem
              topText={item.topText}
              title={item.title}
              dateText={item.dateText}
              services={item.services}
              isLarge={item.isLarge}
              key={`schedule-item-${index}`}
              isInModal
              inModalSliceIndex={item.inModalSliceIndex}
            />
          ))}
        </div>
        <button className={styles.container__btn} onClick={onClose}>{t("Ok")}</button>
      </div>
    </Modal>
  );
};

export default EssentialPlanModal;
