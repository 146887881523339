import React, { useContext } from "react";
import { AppDataContext } from "../contexts/AppData.context";
import { useTranslation } from "react-i18next";
import { useAutoAnimate } from "@formkit/auto-animate/react";

import Stepper from "./Stepper";

import styles from "../styles/MenuBar.module.scss";

const MenuBar = ({ children, pos = "fixed" }) => {
  const { menuBarRef, step } = useContext(AppDataContext);
  const { t } = useTranslation();
  const [animationParent] = useAutoAnimate();

  return (
    <div
      className={`${styles.container} ${
        children ? styles["container--withChildren"] : ""
      }`}
      ref={menuBarRef}
      style={pos === "fixed" ? { position: pos, bottom: 0, left: 0 } : {}}
    >
      <div className={styles.container__children} ref={animationParent}>
        {children}
      </div>
      <Stepper />
      {step === 5 && (
        <div className={styles.container__success}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
          >
            <path
              d="M9.5 1V3H15.5V1H17.5V3H21.5C22.0523 3 22.5 3.44772 22.5 4V20C22.5 20.5523 22.0523 21 21.5 21H3.5C2.94772 21 2.5 20.5523 2.5 20V4C2.5 3.44772 2.94772 3 3.5 3H7.5V1H9.5ZM20.5 8H4.5V19H20.5V8ZM15.5355 10.136L16.9497 11.5503L12 16.5L8.46447 12.9645L9.87868 11.5503L12 13.6716L15.5355 10.136Z"
              fill="#65AE6B"
            />
          </svg>
          <span>{t("Order is processed!")}</span>
        </div>
      )}
    </div>
  );
};

export default MenuBar;
