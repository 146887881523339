import React, { useContext } from "react";

import { useTranslation } from "react-i18next";
import { hasSubService } from "../utils/functions";
import { AppDataContext } from "../contexts/AppData.context";

import styles from "../styles/ScheduleItem.module.scss";

export const ScheduleItem = ({
  topText,
  title,
  dateText,
  services,
  isLarge = false,
  isInModal = false,
  inModalSliceIndex,
}) => {
  const { t } = useTranslation();
  const { selectedServices, coreSoilVisits } = useContext(AppDataContext);

  services = isInModal ? services.slice(0, inModalSliceIndex) : services;

  return (
    <div
      key={`${title}-service-item`}
      className={`${styles.scheduleItem} ${
        isLarge ? styles["scheduleItem--large"] : ""
      } ${isInModal ? styles["scheduleItem--inModal"] : ""}`}
    >
      <div className={styles.scheduleItem__topText}>
        {isInModal ? t(title) : t(topText)}
      </div>
      <div className={styles.scheduleItem__title}>
        {isInModal ? t(topText) : t(title)}
      </div>
      {isLarge ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="283"
          height="2"
          viewBox="0 0 283 2"
          fill="none"
        >
          <path d="M0.5 0.988281H282.5" stroke="black" strokeOpacity="0.05" />
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="248"
          height="2"
          viewBox="0 0 248 2"
          fill="none"
        >
          <path d="M0 0.93042H248" stroke="black" strokeOpacity="0.05" />
        </svg>
      )}

      {dateText && (
        <div className={styles.scheduleItem__dateText}>{t(dateText)}</div>
      )}
      <div
        className={`${styles.scheduleItem__services} ${
          !isLarge ? styles["scheduleItem__services--small"] : ""
        }`}
      >
        {services.map((service, index) => (
          <div
            className={`${styles.scheduleItem__service} ${
              isLarge ? styles["scheduleItem__service--large"] : ""
            }`}
            key={`${service}-service-${index}`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="19"
              viewBox="0 0 18 19"
              fill="none"
            >
              <circle
                cx="9"
                cy="9.93042"
                r="9"
                fill={
                  hasSubService(service, title, selectedServices, coreSoilVisits)
                    ? "#65AE6B"
                    : "gray"
                }
              />
              <path
                d="M5 9.87587L7.84444 12.9304L13 6.93042"
                stroke="white"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <span>{t(service)}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ScheduleItem;
