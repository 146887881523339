import { getCities } from "../middleware/api";

export const getDistanceFromLatLonInKm = (lat1, lon1, lat2, lon2) => {
  const R = 6371; // Radius of the earth in km
  const dLat = deg2rad(lat2 - lat1);
  const dLon = deg2rad(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = R * c; // Distance in km
  return distance;
};

export const deg2rad = (deg) => {
  return deg * (Math.PI / 180);
};

export const addressBreakdown = async (address) => {
  const cities = await getCities();

  // Split the address by comma
  const parts = address.split(", ");

  // Extract the required parts
  const customer_address_number = parts[0].split(" ")[0];
  const customer_address_name = parts[0].split(" ").slice(1).join(" ");
  const customer_city = parts[1].toUpperCase();
  const customer_province = parts[2].split(" ")[0];
  const customer_postal_code = parts[2].split(" ")[1];
  const customer_country = parts[3];

  const cityObject = cities.find(
    (city) => city.city_name.toLowerCase() === customer_city.toLowerCase()
  );

  const customer_city_id = cityObject ? cityObject.city_id : -1;

  return {
    customer_address_number,
    customer_address_name,
    customer_city_id,
    customer_province,
    customer_postal_code,
    customer_country,
  };
};

// TODO: Core soil aeration and its times lines
// TODO: check for ant and spider treatment
const SERVICE_TO_SUB_MAP = {
  "The Essential Plan": {
    "1st visit": [
      "Fertilizer (spring formula)",
      "Mycorrhizae treatment",
      "Lime treatment",
      "Large leaf weed control",
      "Crystal Green",
      "White grub check",
    ],
    "2nd visit": [
      "Fertiziler (summer formula)",
      "Mycorrhizae treatment",
      "Lime treatment",
      "Large leaf weed control",
      "Crystal Green",
    ],
    "3rd visit": [
      "Fertiziler (summer formula)",
      "Mycorrhizae treatment",
      "Lime treatment",
      "Large leaf weed control",
      "Crystal Green",
    ],
    "4th visit": [
      "Fertiziler (fall formula)",
      "Mycorrhizae treatment",
      "Lime treatment",
      "Large leaf weed control",
      "Crystal Green",
      "White grub check",
    ],
    "Additional Services": [],
  },
  "Core Soil Aeration": {
    "1st visit": [],
    "2nd visit": [],
    "3rd visit": [],
    "4th visit": [],
    "Additional Services": [],
  },
  "Core Soil Aeration + Seeding": {
    "1st visit": [],
    "2nd visit": [],
    "3rd visit": [],
    "4th visit": [],
    "Additional Services": [],
  },
  "Limestone application": {
    "1st visit": [],
    "2nd visit": [],
    "3rd visit": [],
    "4th visit": [],
    "Additional Services": [],
  },
  "Crabgrass preventive treatment": {
    "1st visit": ["Crabgrass preventative treatment"],
    "2nd visit": [],
    "3rd visit": [],
    "4th visit": [],
    "Additional Services": [],
  },
  "White grub treatment": {
    "1st visit": [],
    "2nd visit": ["White grub treatment"],
    "3rd visit": [],
    "4th visit": [],
    "Additional Services": [],
  },
  "Ant treatment": {
    "1st visit": [],
    "2nd visit": ["Ant treatment"],
    "3rd visit": [],
    "4th visit": [],
    "Additional Services": [],
  },
  "Spider treatment": {
    "1st visit": [],
    "2nd visit": ["Spider treatment"],
    "3rd visit": [],
    "4th visit": [],
    "Additional Services": [],
  },
  "Ant and Spider treatment": {
    "1st visit": [],
    "2nd visit": ["Ant treatment", "Spider treatment"],
    "3rd visit": [],
    "4th visit": [],
    "Additional Services": [],
  },
  "Chinch bug treatment": {
    "1st visit": [],
    "2nd visit": ["Chinch bug treatment"],
    "3rd visit": [],
    "4th visit": [],
    "Additional Services": [],
  },
  "Top soil and overseeding": {
    "1st visit": [],
    "2nd visit": [],
    "3rd visit": [],
    "4th visit": [],
    "Additional Services": [],
  },
};

export const hasSubService = (
  subService,
  visit,
  selectedServices,
  coreSoilVisits
) => {
  if (subService === "Spring Aeration")
    return (
      coreSoilVisits.includes("Spring Visit") &&
      selectedServices.includes("Core Soil Aeration")
    );
  else if (subService === "Spring Aeration and Seeding")
    return (
      coreSoilVisits.includes("Spring Visit") &&
      selectedServices.includes("Core Soil Aeration + Seeding")
    );
  else if (subService === "Fall Aeration")
    return (
      coreSoilVisits.includes("Fall Visit") &&
      selectedServices.includes("Core Soil Aeration")
    );
  else if (subService === "Fall Aeration and Seeding")
    return (
      coreSoilVisits.includes("Fall Visit") &&
      selectedServices.includes("Core Soil Aeration + Seeding")
    );
  else {
    return selectedServices.some((service) => {
      return SERVICE_TO_SUB_MAP[service][visit]?.includes(subService);
    });
  }
};
