import React, { useState, useEffect, useContext } from "react";
import { AppDataContext } from "../contexts/AppData.context";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";

import { SCHEDULE_ITEMS } from "../constants/SchedulePageConstants";

import ScheduleItem from "../components/ScheduleItem";
import MenuBar from "../components/MenuBar";
import SelectedServices from "../components/SelectedServices";
import PaymentSchedule from "../components/PaymentSchedule";
import ScrollToTop from "../components/ScrollToTop";

import styles from "../styles/SchedulePage.module.scss";
import { useNavigate } from "react-router-dom";

import useDetectPageLeave from "../hooks/useDetectPageLeave";

const SchedulePage = () => {
  const { t } = useTranslation();
  const {
    setStep,
    isMobile,
    selectedServices,
    setSelectedSchedule,
    servicesCost,
    selectedSchedule,
    menuBarRef,
    mainContentRef,
    setMaxStep,
    couponWasApplied,
    contactWasSentToGHL,
    setContactWasSentToGHL,
    name,
    phone,
    selectedAddress,
  } = useContext(AppDataContext);

  const [isPriceDetailsVisible, setIsPriceDetailsVisible] = useState(false);

  const navigate = useNavigate();

  const getDiscount = () => {
    var discount = 0;
    if (selectedSchedule.includes("3 Year")) discount += 5;
    if (couponWasApplied) discount += 20;
    return discount;
  };

  const totalDiscount = (100 - getDiscount()) / 100;

  const totalCost = (servicesCost * totalDiscount * 1.14975).toFixed(2);
  const totalMonthlyCost = (totalCost / 8).toFixed(2);

  useDetectPageLeave(
    {
      firstName: name.split(" ")[0],
      lastName: name.split(" ").slice(1).join(" "),
      phone: phone,
      address: selectedAddress,
      workflowName:
        localStorage.getItem("lang") === "en"
          ? "New Lead - ENG - 2024 - abandoned cart"
          : "New Lead - FR - 2024 - abandoned cart",
    },
    contactWasSentToGHL,
    setContactWasSentToGHL
  );

  useEffect(() => {
    setStep(3);
    setMaxStep((prev) => Math.max(prev, 3));
  }, [setStep]);

  useEffect(() => {
    if (menuBarRef.current && mainContentRef.current) {
      const menuBarHeight = menuBarRef.current.offsetHeight;
      mainContentRef.current.style.paddingBottom = `${menuBarHeight}px`;
    }
  }, [menuBarRef.current?.offsetHeight]);

  return (
    <>
      <ScrollToTop ref={mainContentRef} fullPage />
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 1 }}
        className={styles.container}
        ref={mainContentRef}
      >
        <div className={styles.annualPlan}>{t("ANNUAL PLAN")}</div>
        <h1 className={styles.heading}>{t("Lawn Treatment Schedule")}</h1>
        <p className={styles.explanation}>{t("SCHEDULE_INFO")}</p>
        <div className={styles.scheduleGrid}>
          {SCHEDULE_ITEMS.map((item, index) => (
            <ScheduleItem
              topText={item.topText}
              title={item.title}
              dateText={item.dateText}
              services={item.services}
              isLarge={item.isLarge}
            />
          ))}
        </div>
        {!isMobile && (
          <div className={styles.buttons}>
            <button
              className={`${styles.buttons__btn} ${styles["buttons__btn--back"]}`}
              onClick={() => navigate("/services")}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="15"
                viewBox="0 0 14 15"
                fill="none"
              >
                <path
                  d="M10 3.38222L9.08483 2.48828L4 7.48828L9.08997 12.4883L10 11.5943L5.82005 7.48828L10 3.38222Z"
                  fill="white"
                />
              </svg>
              {t("Back to Services")}
            </button>
            <button
              className={`${styles.buttons__btn} ${styles["buttons__btn--confirm"]}`}
              onClick={() => navigate("/checkout")}
            >
              {t("Confirm")}
            </button>
          </div>
        )}

        {isMobile && (
          <MenuBar>
            {isPriceDetailsVisible && (
              <>
                <PaymentSchedule
                  price={servicesCost}
                  setSelectedSchedule={setSelectedSchedule}
                  selectedSchedule={selectedSchedule}
                />

                <SelectedServices />
              </>
            )}
            <div className={styles.menuBar__priceContainer}>
              <div className={styles.menuBar__totalPrice}>
                {`Total ${totalCost} $`}{" "}
                <span
                  className={styles.menuBar__monthlyTotalPrice}
                >{`(or ${totalMonthlyCost}$/m)`}</span>
              </div>

              <button
                className={styles.menuBar__priceDetailsBtn}
                onClick={() => setIsPriceDetailsVisible(!isPriceDetailsVisible)}
              >
                {isPriceDetailsVisible ? (
                  <>
                    <span>{t("Hide price details")}</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                    >
                      <path
                        d="M6.00036 5.414L3.52536 7.889L2.81836 7.182L6.00036 4L9.18236 7.182L8.47486 7.889L5.99986 5.414H6.00036Z"
                        fill="#65AE6B"
                      />
                    </svg>
                  </>
                ) : (
                  <>
                    <span>{t("See price details")}</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                    >
                      <path
                        d="M6.00036 7.47504L3.52536 5.00004L2.81836 5.70704L6.00036 8.88904L9.18236 5.70704L8.47486 5.00004L5.99986 7.47504H6.00036Z"
                        fill="#65AE6B"
                      />
                    </svg>
                  </>
                )}
              </button>
            </div>

            <button
              className={styles.menuBar__continueBtn}
              onClick={() => navigate("/checkout")}
            >
              Continue
            </button>
          </MenuBar>
        )}
      </motion.div>
    </>
  );
};

export default SchedulePage;
