import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useContext,
} from "react";
import { useTranslation } from "react-i18next";
import { AppDataContext } from "../contexts/AppData.context";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { getDistanceFromLatLonInKm } from "../utils/functions";
import { parsePhoneNumberFromString, AsYouType } from "libphonenumber-js";
import { Tooltip } from "react-tooltip";
import { MdInfo } from "react-icons/md";
import HIGHLIGH_MAP_IMG from "../assets/highlight_map.png";

import { SearchBox } from "@mapbox/search-js-react";
import ScrollToTop from "../components/ScrollToTop";
import Map, { Marker } from "react-map-gl";
import toast from "react-hot-toast";

import ALERT_ICON from "../assets/alert.png";

import DrawControl from "../components/DrawControl";
import AreaPanel from "../components/AreaPanel";
import Modal from "../components/Modal";
import MenuBar from "../components/MenuBar";

import styles from "../styles/AddressPage.module.scss";

import "mapbox-gl/dist/mapbox-gl.css";
import "maplibre-gl/dist/maplibre-gl.css";

import {
  createCustomerAndContract,
  getCities,
  sendContactToGHL,
} from "../middleware/api";

const searchBoxTheme = {
  variables: {
    boxShadow: "none",
    borderRadius: "8px",
    background: "#fff",
  },
  icons: {
    search: "",
  },
};

const AddressPage = () => {
  const [features, setFeatures] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAddressOutOfRange, setIsAddressOutOfRange] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [isReceivePhoneCallClicked, setIsReceivePhoneCallClicked] =
    useState(false);
  const [manualAreaEntry, setManualAreaEntry] = useState(false);
  const [tooLateModalOpen, setTooLateModalOpen] = useState(false);

  const [viewState, setViewState] = useState({
    latitude: 45.500912,
    longitude: -73.751145,
    zoom: 11,
  });

  const {
    long,
    setLong,
    lat,
    setLat,
    area,
    setArea,
    setStep,
    setSelectedAddress,
    selectedAddress,
    name,
    setName,
    phone,
    setPhone,
    isMobile,
    setMaxStep,
    addressObj,
    setAddressObj,
    addressPageIsInstructionModalOpen,
    setAddressPageIsInstructionModalOpen,
    contactWasSentToGHL,
    setContactWasSentToGHL,
  } = useContext(AppDataContext);
  const navigate = useNavigate();

  const mapRef = useRef(null);
  const outletRef = useRef(null);

  const { t } = useTranslation();

  const handleRetrieve = async (result) => {
    const cities = await getCities();

    const { features } = result;
    const { geometry, properties } = features[0];
    const { coordinates } = geometry;
    const { context } = properties;
    const { full_address: fullAddress } = properties;
    const [long, lat] = coordinates;

    const addressCity = context.place.name;
    const cityObject = cities.find(
      (city) => city.city_name.toLowerCase() === addressCity.toLowerCase()
    );

    setLong(long);
    setLat(lat);
    setSelectedAddress(fullAddress);
    setIsAddressOutOfRange(false);
    setViewState({
      latitude: lat,
      longitude: long,
      zoom: 16,
    });

    const addressObj = {
      customer_address_number: context?.address?.address_number,
      customer_address_name: context?.address?.street_name,
      customer_city_id: cityObject ? cityObject?.city_id : null,
      customer_province: context?.region?.name,
      customer_postal_code: context?.postcode?.name,
      customer_country: context?.country?.name,
      customer_latitude: lat,
      customer_longitude: long,
    };
    setAddressObj(addressObj);

    const distance = getDistanceFromLatLonInKm(lat, long, 45.42141, -73.76566);
    if (distance > 49.5) {
      setIsAddressOutOfRange(true);
    }
  };

  const onUpdate = useCallback((e) => {
    setManualAreaEntry(false);
    setFeatures((currFeatures) => {
      const newFeatures = { ...currFeatures };
      for (const f of e.features) {
        newFeatures[f.id] = f;
      }
      return newFeatures;
    });
  }, []);

  const onDelete = useCallback((e) => {
    setFeatures((currFeatures) => {
      const newFeatures = { ...currFeatures };
      for (const f of e.features) {
        delete newFeatures[f.id];
      }
      return newFeatures;
    });
  }, []);

  const areButtonsDisabled =
    selectedAddress === "" ||
    name === "" ||
    phone === "" ||
    isAddressOutOfRange;

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setIsReceivePhoneCallClicked(false);
  };

  const handleGetOnlineQuote = async () => {
    if (areButtonsDisabled) {
      toast.error(
        t(
          "Please fill in all fields and make sure your address is within our service area."
        )
      );
    } else if (area == 0) {
      toast.error(t("Please select your lawn area on the map."));
    } else if (isDateOutOfRange()) {
      setTooLateModalOpen(true);
      await handleReceivePhoneCall();
    } else if (area >= 30000) {
      setIsModalOpen(true);
    } else {
      navigate("/services");
    }
  };

  const handleReceivePhoneCall = async () => {
    if (areButtonsDisabled) {
      toast.error(
        t(
          "Please fill in all fields and make sure your address is within our service area."
        )
      );
    } else if (area === 0) {
      toast.error(t("Please select your lawn area on the map."));
    } else {
      try {
        const addressObjValues = Object.values(addressObj);

        if (addressObjValues.includes(null)) {
          toast.error(
            t(
              "There was a problem with your address. Please select it correctly from the dropdown."
            )
          );
          return;
        }

        const nameParts = name.split(" ");

        const customerData = {
          customer_first_name: nameParts[0],
          customer_last_name: nameParts.slice(1).join(" "),
          customer_phone: phone,
          ...addressObj,
          surface: area,
          service_ids: [],
          discount: 0,
          customer_language_id: localStorage.getItem("lang") === "en" ? 1 : 2,
          call_requested: true,
        };

        await createCustomerAndContract(customerData);

        if (!contactWasSentToGHL) {
          await sendContactToGHL({
            firstName: nameParts[0],
            lastName: nameParts.slice(1).join(" "),
            phone: phone,
            address: selectedAddress,
            workflowName:
              localStorage.getItem("lang") === "en"
                ? "New Lead - ENG - 2024 - receive phone call"
                : "New Lead - FR - 2024 - receive phone call",
          });

          setContactWasSentToGHL(true);
        }

        if (!isDateOutOfRange()) {
          setIsReceivePhoneCallClicked(true);
          setIsModalOpen(true);
        } else {
          setTooLateModalOpen(true);
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  const handlePhoneChange = (e) => {
    const phoneNumber = new AsYouType("US").input(e.target.value);
    setPhone(phoneNumber);

    const parsedPhoneNumber = parsePhoneNumberFromString(phoneNumber, "US");
    if (!parsedPhoneNumber?.isValid()) {
      setPhoneError(true);
    } else {
      setPhoneError(false);
    }
  };

  const isDateOutOfRange = () => {
    const today = new Date();
    const start = new Date(today.getFullYear(), 6, 15); // July 15 of current year
    const end = new Date(today.getFullYear() + 1, 0, 1); // January 1 of next year

    return today >= start && today < end;
  };

  useEffect(() => {
    setStep(1);
    setMaxStep((prev) => Math.max(prev, 1));
  }, [setStep]);

  return (
    <>
      <ScrollToTop ref={outletRef} />
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 1 }}
        className={styles.addressPage}
        ref={outletRef}
      >
        {!isMobile && (
          <section className={styles.infoSection}>
            <div className={styles.infoSection__header}>
              <div className={styles.freeQuote}>{t("FREE QUOTE")}</div>
              <h1 style={{ textTransform: "capitalize" }}>
                {t("Get the price for your lawn treatment")}
              </h1>
            </div>

            {!isMobile && (
              <div className={styles.alert}>
                <img
                  src={ALERT_ICON}
                  alt="alert icon"
                  className={styles.alert__icon}
                />
                <p className={styles.alert__text}>{t("ALERT_NOTE")}</p>
              </div>
            )}

            <form className={styles.form}>
              <SearchBox
                accessToken={process.env.REACT_APP_MAPBOX_KEY}
                value={selectedAddress}
                onChange={(newAddress) => setSelectedAddress(newAddress)}
                map={mapRef.current}
                onRetrieve={handleRetrieve}
                theme={searchBoxTheme}
                placeholder={t("Enter your address")}
              />
              {isAddressOutOfRange && (
                <div className={styles.form__error}>
                  {t(
                    "Unfortunately, we do not service your area. We apologize for this inconvenience."
                  )}
                </div>
              )}
              <input
                type="text"
                id="name"
                placeholder={t("Full Name")}
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <input
                type="text"
                id="phone"
                placeholder="+1 (123) 123 - 1234"
                value={phone}
                onChange={handlePhoneChange}
              />
              {phoneError && phone && (
                <div className={styles.form__error}>
                  {t("Your phone number is invalid!")}
                </div>
              )}
              <div className={styles.form__areaBox}>
                <span className={styles.form__areaLabel}>
                  {t(
                    "Enter your lawn's total area or highlight on the map (sqft)"
                  )}
                </span>
                <MdInfo className={styles.form__areaIcon} id="formAreaIcon" />
                <Tooltip
                  anchorSelect="#formAreaIcon"
                  place="top"
                  className={styles.form__areaTooltip}
                >
                  <img
                    src={HIGHLIGH_MAP_IMG}
                    alt="Map highlighting instructions"
                    className={styles.form__areaImg}
                  />
                  <ol>
                    <li>{t("Find your address.")}</li>
                    <li>
                      {t(
                        "Enter the total area of your lawn in the input field or highlight on the map."
                      )}
                    </li>
                  </ol>
                </Tooltip>
              </div>
              <input
                type="number"
                step="0.01"
                id="area"
                placeholder="0.00 sqft"
                value={area || `${area}`}
                onChange={(e) => {
                  setManualAreaEntry(true);
                  setArea(parseFloat(e.target.value));
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                  }
                }}
              />
            </form>

            <div className={styles.buttons}>
              <button
                className={styles.buttons__btn}
                onClick={handleGetOnlineQuote}
              >
                {t("Get an Online Quote")}
              </button>
              <span className={styles.buttons__separator}>{t("Or")}</span>
              <button
                className={`${styles.buttons__btn} ${styles["buttons__btn--other"]}`}
                onClick={handleReceivePhoneCall}
              >
                {t("Receive a Phone Call")}
              </button>
            </div>
          </section>
        )}

        {!isMobile && (
          <section className={styles.mapSection}>
            {!isMobile && (
              <AreaPanel
                polygons={Object.values(features)}
                manualAreaEntry={manualAreaEntry}
              />
            )}
            <div className={styles.mapContainer}>
              <Map
                {...viewState}
                mapStyle="mapbox://styles/mapbox/satellite-v9"
                mapboxAccessToken={process.env.REACT_APP_MAPBOX_KEY}
                ref={mapRef}
                onMove={(evt) => setViewState(evt.viewState)}
                height="100%"
              >
                {isMobile && (
                  <AreaPanel
                    polygons={Object.values(features)}
                    manualAreaEntry={manualAreaEntry}
                  />
                )}
                {long !== 0 && lat !== 0 && (
                  <Marker longitude={long} latitude={lat} color="red" />
                )}{" "}
                <DrawControl
                  position="top-left"
                  displayControlsDefault={false}
                  controls={{
                    polygon: true,
                    trash: true,
                  }}
                  defaultMode="draw_polygon"
                  onCreate={onUpdate}
                  onUpdate={onUpdate}
                  onDelete={onDelete}
                />
              </Map>
            </div>
          </section>
        )}

        {isModalOpen && (
          <Modal
            isOpen={isModalOpen}
            onClose={handleCloseModal}
            showCloseButton={false}
          >
            <div className={styles.modalContainer}>
              <div className={styles.freeQuote}>
                {!isReceivePhoneCallClicked
                  ? t("Custom Quote")
                  : t("Thank you for completing the form")}
              </div>
              <div className={styles.modalHeading}>{t("Hang tight!")}</div>
              <div className={styles.modalText}>
                {!isReceivePhoneCallClicked
                  ? t(
                      "Your information was successfully submitted. Due to the large size of your lawn, we will be contacting you by phone in order to provide you with accurate pricing."
                    )
                  : t(
                      "Your information was successfully submitted. We will be contacting you very soon!"
                    )}
              </div>
              <div className={styles.modalButtons}>
                {area >= 30000 && !isReceivePhoneCallClicked && (
                  <button
                    className={styles.buttons__btn}
                    onClick={handleReceivePhoneCall}
                  >
                    {t("Request a call")}
                  </button>
                )}
                <button
                  className={styles.buttons__btn}
                  onClick={() =>
                    (window.location.href =
                      "https://visiongazon.com/services")
                  }
                >
                  {t("Back to our services page")}
                </button>
              </div>
            </div>
          </Modal>
        )}

        {tooLateModalOpen && (
          <Modal
            isOpen={tooLateModalOpen}
            onClose={() => setTooLateModalOpen(false)}
            showCloseButton={false}
          >
            <div className={styles.modalContainer}>
              <div className={styles.modalText} style={{ maxWidth: "80%", marginBottom: 0 }}>
                <p>{t("Unfortunately, our online quote system is not available from July 15th to January 1st.")}</p>
                <p>{t("We will be in contact with you in order to provide you a personalized quote!")}</p>
              </div>
            </div>
          </Modal>
        )}

        {isMobile && (
          <>
            <MenuBar pos="bottom">
              <section className={styles.infoSection}>
                <div className={styles.infoSection__header}>
                  <div className={styles.freeQuote}>{t("FREE QUOTE")}</div>
                  <h1 style={{ textTransform: "capitalize" }}>
                    {t("Get the price for your lawn treatment")}
                  </h1>
                </div>

                {!isMobile && (
                  <div className={styles.alert}>
                    <img
                      src={ALERT_ICON}
                      alt="alert icon"
                      className={styles.alert__icon}
                    />
                    <p className={styles.alert__text}>{t("ALERT_NOTE")}</p>
                  </div>
                )}

                <form className={styles.form}>
                  <SearchBox
                    accessToken={process.env.REACT_APP_MAPBOX_KEY}
                    value={selectedAddress}
                    onChange={(newAddress) => setSelectedAddress(newAddress)}
                    map={mapRef.current}
                    onRetrieve={handleRetrieve}
                    theme={searchBoxTheme}
                    placeholder={t("Enter your address")}
                  />
                  {isAddressOutOfRange && (
                    <div className={styles.form__error}>
                      {t(
                        "Unfortunately, we do not service your area. We apologize for this inconvenience."
                      )}
                    </div>
                  )}
                  <input
                    type="text"
                    id="name"
                    placeholder={t("Full Name")}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                      }
                    }}
                  />
                  <input
                    type="text"
                    id="phone"
                    placeholder="+1 (123) 123 - 1234"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                      }
                    }}
                  />
                  {phoneError && phone && (
                    <div className={styles.form__error}>
                      {t("Your phone number is invalid!")}
                    </div>
                  )}
                  <div className={styles.form__areaBox}>
                    <span className={styles.form__areaLabel}>
                      {t(
                        "Enter your lawn's total area or highlight on the map"
                      )}{" "}
                      {"(sqft)"}
                    </span>
                    <MdInfo
                      className={styles.form__areaIcon}
                      id="formAreaIcon"
                    />
                    <Tooltip
                      anchorSelect="#formAreaIcon"
                      place="top"
                      className={styles.form__areaTooltip}
                    >
                      <img
                        src={HIGHLIGH_MAP_IMG}
                        alt="Map highlighting instructions"
                        className={styles.form__areaImg}
                      />
                      <ol>
                        <li>{t("Find your address.")}</li>
                        <li>
                          {t(
                            "Enter the total area of your lawn in the input field or highlight on the map."
                          )}
                        </li>
                      </ol>
                    </Tooltip>
                  </div>
                  <input
                    type="number"
                    step="0.01"
                    id="area"
                    placeholder="0.00 sqft"
                    value={area || `${area}`}
                    onChange={(e) => {
                      setManualAreaEntry(true);
                      setArea(parseFloat(e.target.value));
                    }}
                  />
                </form>

                <div className={styles.buttons}>
                  <button
                    className={styles.buttons__btn}
                    onClick={handleGetOnlineQuote}
                  >
                    {t("Get an Online Quote")}
                  </button>
                  <span className={styles.buttons__separator}>{t("Or")}</span>
                  <button
                    className={`${styles.buttons__btn} ${styles["buttons__btn--other"]}`}
                    onClick={handleReceivePhoneCall}
                  >
                    {t("Receive a Phone Call")}
                  </button>
                </div>
              </section>
            </MenuBar>

            <Modal
              isOpen={addressPageIsInstructionModalOpen}
              onClose={() => {
                setAddressPageIsInstructionModalOpen(false);
              }}
            >
              <div className={styles.modalContainer}>
                <div className={styles.modalHeading}>{t("Attention!")}</div>
                <div className={styles.modalText}>
                  {t("For the best experience, please use a desktop device.")}
                </div>
              </div>
            </Modal>
          </>
        )}
      </motion.div>
    </>
  );
};

export default AddressPage;
