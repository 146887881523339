import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

import Modal from "./Modal";

import { SERVICE_MODAL_DATA } from "../constants/ServiceModalConstants";
import RECOMMENDATION_ICON from "../assets/serviceModals/recommendation.png";
import ATTENTION_ICON from "../assets/serviceModals/attention-required.png";
import EXTRA_TIP_ICON from "../assets/serviceModals/extra-tip.png";

import styles from "../styles/ServiceModal.module.scss";

const Card = ({ title, icon, messages, bgColor }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.card} style={{ background: bgColor }}>
      <div className={styles.card__header}>
        <img src={icon} alt={`${title} icon`} className={styles.card__icon} />
        <span className={styles.card__title}>{t(title)}</span>
      </div>
      <ul className={styles.card__messages}>
        {messages?.map((message, index) => (
          <li className={styles.card__message} key={`card-message-${index}`}>
            {t(message)}
          </li>
        ))}
      </ul>
    </div>
  );
};

const ServiceModal = ({ serviceName, onClose }) => {
  const service = SERVICE_MODAL_DATA[serviceName];

  const [currDescription, setCurrDescription] = useState("what");

  const { t } = useTranslation();

  return (
    <Modal isOpen={!!service} onClose={onClose}>
      <div className={styles.container}>
        <div className={styles.container__col}>
          <h2>{t(serviceName)}</h2>
          <div className={styles.container__btns}>
            <button
              className={`${styles.container__btn} ${
                currDescription === "what"
                  ? styles["container__btn--selected"]
                  : ""
              }`}
              onClick={() => setCurrDescription("what")}
            >
              {t("What It Is?")}
            </button>
            <button
              className={`${styles.container__btn} ${
                currDescription === "why"
                  ? styles["container__btn--selected"]
                  : ""
              }`}
              onClick={() => setCurrDescription("why")}
            >
              {t("Why Do It?")}
            </button>
            <button
              className={`${styles.container__btn} ${
                currDescription === "how"
                  ? styles["container__btn--selected"]
                  : ""
              }`}
              onClick={() => setCurrDescription("how")}
            >
              {t("How It's Done?")}
            </button>
          </div>
          <div className={styles.container__description}>
            {t(service[currDescription])
              .split("<br />")
              .map((line, index) => {
                const txt = t(line);
                if (txt.startsWith("•")) {
                  return (
                    <ul key={`desc-list-${index}`}>
                      <li>{txt.slice(1).trim()}</li>
                    </ul>
                  );
                } else {
                  return <p key={`desct-p-${index}`}>{t(line)}</p>;
                }
              })}
          </div>
        </div>
        <div
          className={`${styles.container__col} ${styles["container__col--r"]}`}
        >
          <Carousel infiniteLoop showThumbs={false} showStatus={false} className={styles.carousel}>
            {service.images.map((image, index) => (
              <div key={`carousel-image-${index}`} className={styles.carouselImg}>
                <img
                  src={image}
                  alt={`${serviceName} service image ${index + 1}`}
                />
              </div>
            ))}
          </Carousel>

          <Card
            title="Recommendation"
            icon={RECOMMENDATION_ICON}
            messages={service.recommendations}
            bgColor="#E7FFE3"
          />
          <Card
            title="Extra Tip"
            icon={EXTRA_TIP_ICON}
            messages={service.tips}
            bgColor="#FFFCED"
          />
          <Card
            title="Attention Required"
            icon={ATTENTION_ICON}
            messages={service.attentions}
            bgColor="#FFEDEC"
          />
        </div>
      </div>
    </Modal>
  );
};

export default ServiceModal;
