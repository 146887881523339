import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

import styles from "../styles/PromoBanner.module.scss";

const PromoBanner = ({ setIsPromoBannerVisible }) => {
  const { t } = useTranslation();

  const handleCodeClick = async () => {
    try {
      await navigator.clipboard.writeText("PROMO20");
      toast.success("Code copied to clipboard!");
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className={styles.banner}>
      <p className={styles.banner__text}>
        {t("To get 20% off for the 2024 season, please use")}{" "}
        <span className={styles.banner__code} onClick={handleCodeClick}>
          PROMO20
        </span>
      </p>

      <button
        className={styles.banner__close}
        onClick={() => setIsPromoBannerVisible(false)}
      >
        <div className={styles.banner__x}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <title>close</title>
            <g fill="none">
              <path
                d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z"
                fill="currentColor"
              ></path>
            </g>
          </svg>
        </div>
      </button>
    </div>
  );
};

export default PromoBanner;
