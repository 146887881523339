import React, { useContext, useEffect } from "react";
import area from "@turf/area";
import { AppDataContext } from "../contexts/AppData.context";
import { useTranslation } from "react-i18next";

import styles from "../styles/AreaPanel.module.scss";

const AreaPanel = ({ polygons, manualAreaEntry }) => {
  const { setArea, area: calculatedArea } = useContext(AppDataContext);
  const { t } = useTranslation();

  useEffect(() => {
    if (!manualAreaEntry) {
      // Only calculate area automatically if area was not manually entered
      let polygonArea = 0;
      for (const polygon of polygons) {
        polygonArea += area(polygon);
      }

      setArea((polygonArea * 10.764).toFixed(2));
    }
  }, [polygons, manualAreaEntry]);

  return (
    <div className={styles.lawnArea}>
      {t(`Enter your lawn's total area or highlight on the map (sqft)`)}
    </div>
  );
};

export default AreaPanel;
