import React, { useEffect, useContext, useRef } from "react";
import { AppDataContext } from "../contexts/AppData.context";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";

import SUCCESS_GRASS from "../assets/success-grass.png";
import MenuBar from "../components/MenuBar";
import ScrollToTop from "../components/ScrollToTop";

import styles from "../styles/SuccessPage.module.scss";

const SuccessPage = () => {
  const { setStep, isMobile, setMaxStep } = useContext(AppDataContext);
  const { t } = useTranslation();

  const outletRef = useRef(null);

  useEffect(() => {
    setStep(5);
    setMaxStep((prev) => Math.max(prev, 5));
  }, [setStep]);

  return (
    <>
      <ScrollToTop ref={outletRef} fullPage/>
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 1 }}
        className={styles.container}
        ref={outletRef}
      >
        <img src={SUCCESS_GRASS} alt="grass image" />
        <div className={styles.subText}>{t("Thank you for your payment")}</div>
        <h1>{t("Hang tight! Your beautiful lawn is on its way.")}</h1>
        <p>
          {t(
            "Your payment is currently being processed and will be completed within the next 24-48 hours. We will be contacting you if any questions arise regarding your requests."
          )}
        </p>
        <button
          onClick={() =>
            (window.location.href = "https://visiongazon.com/services")
          }
        >
          {t("Learn More About Our Services")}
        </button>

        {isMobile && <MenuBar />}
      </motion.div>
    </>
  );
};

export default SuccessPage;
