import React, { useState, useEffect, useContext } from "react";
import { AppDataContext } from "../contexts/AppData.context";
import { motion } from "framer-motion";
import toast from "react-hot-toast";

import ServiceModal from "../components/ServiceModal";
import EssentialPlanModal from "../components/EssentialPlanModal";
import SelectedServices from "../components/SelectedServices";
import PaymentSchedule from "../components/PaymentSchedule";
import ServiceItem from "../components/ServiceItem";
import MenuBar from "../components/MenuBar";
import ScrollToTop from "../components/ScrollToTop";

import Map, { Marker } from "react-map-gl";

import { SERVICES } from "../constants/ServicesPageConstants";
import { useTranslation } from "react-i18next";
import { getServiceCost } from "../utils/priceCalculator";

import styles from "../styles/ServicesPage.module.scss";
import { useNavigate } from "react-router-dom";

import useDetectPageLeave from "../hooks/useDetectPageLeave";

import "mapbox-gl/dist/mapbox-gl.css";
import "maplibre-gl/dist/maplibre-gl.css";

const ServicesPage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEssentialModalOpen, setIsEssentialModalOpen] = useState(false);
  const [currModalService, setCurrModalService] = useState(null);
  const [isPriceDetailsVisible, setIsPriceDetailsVisible] = useState(false);

  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    setSelectedSchedule,
    selectedAddress,
    long,
    lat,
    selectedServices,
    setSelectedServices,
    servicesCost,
    setServicesCost,
    selectedSchedule,
    setStep,
    isMobile,
    area,
    menuBarRef,
    mainContentRef,
    coreSoilVisits,
    setMaxStep,
    couponWasApplied,
    name,
    phone,
    contactWasSentToGHL,
    setContactWasSentToGHL,
    setCouponWasApplied,
    setPromoCode,
  } = useContext(AppDataContext);

  const getDiscount = () => {
    var discount = 0;
    if (selectedSchedule.includes("3 Year")) discount += 5;
    if (couponWasApplied) discount += 20;
    return discount;
  };

  const totalDiscount = (100 - getDiscount()) / 100;

  const totalCost = (servicesCost * totalDiscount * 1.14975).toFixed(2);
  const totalMonthlyCost = (totalCost / 8).toFixed(2);

  const handleBackClick = () => {
    navigate("/");
  };

  const onServiceBtnClick = (serviceName) => {
    let newSelectedServices = [...selectedServices];

    const addService = (name) => {
      newSelectedServices = newSelectedServices.filter(
        (service) => service !== name
      );
      newSelectedServices.push(name);
    };

    const removeService = (name) => {
      newSelectedServices = newSelectedServices.filter(
        (service) => service !== name
      );
    };

    const serviceIsSelected = newSelectedServices.includes(serviceName);
    const antAndSpiderTreatmentSelected = newSelectedServices.includes(
      "Ant and Spider treatment"
    );
    const otherServiceName =
      serviceName === "Ant treatment" ? "Spider treatment" : "Ant treatment";

    if (serviceName === "Ant treatment" || serviceName === "Spider treatment") {
      if (newSelectedServices.includes(otherServiceName)) {
        removeService("Ant treatment");
        removeService("Spider treatment");
        addService("Ant and Spider treatment");
      } else if (antAndSpiderTreatmentSelected) {
        removeService("Ant and Spider treatment");
        addService(otherServiceName);
      } else if (serviceIsSelected) {
        removeService(serviceName);
      } else {
        addService(serviceName);
      }
    } else if (serviceName === "Core Soil Aeration") {
      if (newSelectedServices.includes("Core Soil Aeration + Seeding")) {
        removeService("Core Soil Aeration + Seeding");
      } else if (serviceIsSelected) {
        removeService(serviceName);
      } else {
        addService("Core Soil Aeration");
      }
    } else if (serviceName === "Core Soil Aeration + Seeding") {
      if (newSelectedServices.includes("Core Soil Aeration")) {
        removeService("Core Soil Aeration");
        addService("Core Soil Aeration + Seeding");
      } else if (serviceIsSelected) {
        removeService(serviceName);
        addService("Core Soil Aeration");
      } else {
        addService("Core Soil Aeration + Seeding");
      }
    } else {
      if (serviceIsSelected) {
        removeService(serviceName);
      } else {
        addService(serviceName);
      }
    }

    setSelectedServices(newSelectedServices);
    setServicesCost(
      newSelectedServices.reduce(
        (acc, curr) =>
          acc +
          (curr.includes("Core Soil")
            ? getServiceCost(curr, area) * coreSoilVisits.length
            : getServiceCost(curr, area)),
        0
      )
    );
  };

  const onInfoBtnClick = (serviceName) => {
    setCurrModalService(serviceName);
    setIsModalOpen(true);
  };

  const onEssentialInfoBtnClick = () => {
    setIsEssentialModalOpen(true);
  };

  const handleContinue = () => {
    if (
      (selectedServices?.includes("Core Soil Aeration + Seeding") ||
        selectedServices?.includes("Core Soil Aeration")) &&
      coreSoilVisits.length === 0
    ) {
      toast.error(
        t("Please select at least one visit for Core Soil Aeration!")
      );
      return;
    }

    if (selectedServices.length === 0) {
      toast.error(t("Please select at least one service!"));
      return;
    }

    if (selectedSchedule.length === 0) {
      toast.error(t("Please select a payment schedule!"));

      if (isMobile) setIsPriceDetailsVisible(true);

      return;
    }

    setStep(3);
    navigate("/schedule");
  };

  useDetectPageLeave(
    {
      firstName: name.split(" ")[0],
      lastName: name.split(" ").slice(1).join(" "),
      phone: phone,
      address: selectedAddress,
      workflowName:
        localStorage.getItem("lang") === "en"
          ? "New Lead - ENG - 2024 - abandoned cart"
          : "New Lead - FR - 2024 - abandoned cart",
    },
    contactWasSentToGHL,
    setContactWasSentToGHL
  );

  useEffect(() => {
    setStep(2);
    setMaxStep((prev) => Math.max(prev, 2));
  }, [setStep]);

  useEffect(() => {
    if (menuBarRef.current && mainContentRef.current) {
      const menuBarHeight = menuBarRef.current.offsetHeight;
      mainContentRef.current.style.paddingBottom = `${menuBarHeight}px`;
    }
  }, [menuBarRef.current?.offsetHeight]);

  useEffect(() => {
    // update services cost if coir soil visits change
    setServicesCost(
      selectedServices.reduce(
        (acc, curr) =>
          acc +
          (curr.includes("Core Soil")
            ? getServiceCost(curr, area) * coreSoilVisits.length
            : getServiceCost(curr, area)),
        0
      )
    );
  }, [coreSoilVisits.length]);

  useEffect(() => {
    if (isPriceDetailsVisible) {
      document.body.classList.add("body-no-scroll-menu-open");
    } else {
      document.body.classList.remove("body-no-scroll-menu-open");
    }

    return () => {
      document.body.classList.remove("body-no-scroll-menu-open");
    };
  }, [isPriceDetailsVisible]);

  useEffect(() => {
    setPromoCode("PROMO20");
    setCouponWasApplied(true);
  }, []);

  return (
    <>
      <ScrollToTop ref={mainContentRef} fullPage={true} />
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 1 }}
        className={styles.servicesPage}
        ref={mainContentRef}
      >
        <div className={styles.container}>
          <div className={styles.container__col}>
            <div className={styles.subText}>{t("We provide top services")}</div>
            <h1 style={{ textTransform: "capitalize" }}>
              {t("Choose your services")}
            </h1>

            {SERVICES.map((service, index) => (
              <ServiceItem
                key={`service-${index}`}
                title={service.title}
                description={service.description}
                options={service.options}
                bgColor={service.bgColor}
                icon={service.icon}
                onInfoBtnClick={onInfoBtnClick}
                onServiceBtnClick={onServiceBtnClick}
                onEssentialInfoBtnClick={onEssentialInfoBtnClick}
              />
            ))}
          </div>
          {!isMobile && (
            <div
              className={`${styles.container__col} ${styles["container__col--dark"]}`}
            >
              <div className={styles.addressContainer}>
                <div className={styles.addressContainer__smallPill}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="17"
                    viewBox="0 0 12 17"
                    fill="none"
                  >
                    <path
                      d="M5.78377 8.05343C5.26789 8.05343 4.77314 7.8485 4.40836 7.48371C4.04357 7.11893 3.83864 6.62418 3.83864 6.1083C3.83864 5.59242 4.04357 5.09766 4.40836 4.73288C4.77314 4.3681 5.26789 4.16317 5.78377 4.16317C6.29966 4.16317 6.79441 4.3681 7.15919 4.73288C7.52397 5.09766 7.72891 5.59242 7.72891 6.1083C7.72891 6.36374 7.67859 6.61667 7.58084 6.85267C7.48309 7.08866 7.33981 7.30309 7.15919 7.48371C6.97857 7.66434 6.76414 7.80761 6.52814 7.90537C6.29215 8.00312 6.03921 8.05343 5.78377 8.05343ZM5.78377 0.661926C4.33931 0.661926 2.954 1.23574 1.93261 2.25713C0.911215 3.27852 0.337402 4.66383 0.337402 6.1083C0.337402 10.1931 5.78377 16.223 5.78377 16.223C5.78377 16.223 11.2301 10.1931 11.2301 6.1083C11.2301 4.66383 10.6563 3.27852 9.63494 2.25713C8.61355 1.23574 7.22824 0.661926 5.78377 0.661926Z"
                      fill="#65AE6B"
                    />
                  </svg>
                  <span>{t("Address")}</span>
                </div>

                <span className={styles.addressContainer__address}>
                  {selectedAddress}
                </span>
              </div>

              <div className={styles.mapContainer}>
                <Map
                  initialViewState={{
                    latitude: lat,
                    longitude: long,
                    zoom: 17,
                  }}
                  mapStyle="mapbox://styles/mapbox/satellite-v9"
                  mapboxAccessToken={process.env.REACT_APP_MAPBOX_KEY}
                  scrollZoom={false}
                  zoom={17}
                >
                  {long !== 0 && lat !== 0 && (
                    <Marker longitude={long} latitude={lat} color="red" />
                  )}
                </Map>
              </div>

              <PaymentSchedule
                price={servicesCost}
                setSelectedSchedule={setSelectedSchedule}
                selectedSchedule={selectedSchedule}
              />

              <SelectedServices />

              <div className={styles.disclaimer}>
                {t("* You can use your promo code before checking out!")}
              </div>

              <button className={styles.continueBtn} onClick={handleContinue}>
                {t("Continue")}
              </button>
            </div>
          )}

          {isModalOpen && (
            <ServiceModal
              serviceName={currModalService}
              onClose={() => setIsModalOpen(false)}
            />
          )}
          {isEssentialModalOpen && (
            <EssentialPlanModal
              isOpen={isEssentialModalOpen}
              onClose={() => setIsEssentialModalOpen(false)}
            />
          )}
        </div>
        {!isMobile && (
          <button className={styles.backButton} onClick={handleBackClick}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="15"
              viewBox="0 0 15 15"
              fill="none"
            >
              <path
                d="M10.5 3.39394L9.58483 2.5L4.5 7.5L9.58997 12.5L10.5 11.6061L6.32005 7.5L10.5 3.39394Z"
                fill="white"
              />
            </svg>
            {t("Back")}
          </button>
        )}
        {isMobile && (
          <MenuBar>
            {isPriceDetailsVisible && (
              <>
                <PaymentSchedule
                  price={servicesCost}
                  setSelectedSchedule={setSelectedSchedule}
                  selectedSchedule={selectedSchedule}
                />

                <SelectedServices />
              </>
            )}
            <div className={styles.menuBar__priceContainer}>
              <div className={styles.menuBar__totalPrice}>
                {`Total ${totalCost} $`}{" "}
                <span
                  className={styles.menuBar__monthlyTotalPrice}
                >{`(or ${totalMonthlyCost}$/m)`}</span>
              </div>

              <button
                className={styles.menuBar__priceDetailsBtn}
                onClick={() => setIsPriceDetailsVisible(!isPriceDetailsVisible)}
              >
                {isPriceDetailsVisible ? (
                  <>
                    <span>{t("Hide price details")}</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                    >
                      <path
                        d="M6.00036 5.414L3.52536 7.889L2.81836 7.182L6.00036 4L9.18236 7.182L8.47486 7.889L5.99986 5.414H6.00036Z"
                        fill="#65AE6B"
                      />
                    </svg>
                  </>
                ) : (
                  <>
                    <span>{t("See price details")}</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                    >
                      <path
                        d="M6.00036 7.47504L3.52536 5.00004L2.81836 5.70704L6.00036 8.88904L9.18236 5.70704L8.47486 5.00004L5.99986 7.47504H6.00036Z"
                        fill="#65AE6B"
                      />
                    </svg>
                  </>
                )}
              </button>
            </div>

            <button
              className={styles.menuBar__continueBtn}
              onClick={handleContinue}
            >
              {t("Continue")}
            </button>
          </MenuBar>
        )}
      </motion.div>
    </>
  );
};

export default ServicesPage;
