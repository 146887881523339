const serviceCostData = {
  "The Essential Plan": {
    1999: 89,
    3999: 99,
    5999: 119,
    7999: 139,
    9999: 159,
    12499: 189,
    14999: 209,
    19999: 249,
    24999: 299,
    29999: 349,
  },
  "Core Soil Aeration": {
    1999: 99,
    3999: 119,
    5999: 139,
    7999: 149,
    9999: 169,
    12499: 189,
    14999: 199,
    19999: 249,
    24999: 329,
    29999: 399,
  },
  "Core Soil Aeration + Seeding": {
    1999: 199,
    3999: 239,
    5999: 279,
    7999: 299,
    9999: 339,
    12499: 379,
    14999: 399,
    19999: 499,
    24999: 659,
    29999: 799,
  },
  "Limestone application": {
    1999: 39,
    3999: 49,
    5999: 59,
    7999: 69,
    9999: 79,
    12499: 89,
    14999: 99,
    19999: 109,
    24999: 119,
    29999: 129,
  },
  "Crabgrass preventive treatment": {
    1999: 99,
    3999: 149,
    5999: 199,
    7999: 239,
    9999: 279,
    12499: 319,
    14999: 349,
    19999: 399,
    24999: 449,
    29999: 549,
  },
  "White grub treatment": {
    1999: 149,
    3999: 179,
    5999: 219,
    7999: 249,
    9999: 279,
    12499: 299,
    14999: 349,
    19999: 399,
    24999: 449,
    29999: 499,
  },
  "Ant treatment": {
    1999: 99,
    4999: 149,
    29999: 199,
  },
  "Spider treatment": {
    1999: 99,
    4999: 149,
    29999: 199,
  },
  "Ant and Spider treatment": {
    1999: 149,
    4999: 199,
    29999: 249,
  },
  "Chinch bug treatment": {
    1999: 49,
    3999: 69,
    5999: 99,
    7999: 139,
    9999: 179,
    12499: 199,
    14999: 249,
    19999: 299,
    24999: 349,
    29999: 399,
  },
};

export const getServiceCost = (service, area) => {
  const pricing = serviceCostData[service];
  if (!pricing) {
    return "Service not found";
  }

  for (let upperLimit in pricing) {
    if (area <= parseInt(upperLimit)) {
      return service === "The Essential Plan"
        ? pricing[upperLimit] * 4
        : pricing[upperLimit];
    }
  }
  return "Price not found for the given area";
};
