import { useEffect, forwardRef } from "react";
import { useLocation } from "react-router-dom";

const ScrollToTop = forwardRef((props, ref) => {
  const { pathname } = useLocation();

  useEffect(() => {
    if (ref.current) {
      window.scrollTo({
        top: props.fullPage
          ? 0
          : ref.current.offsetTop - 40,
        behavior: "smooth",
      });
    }
  }, [pathname, ref]);

  return null;
});

export default ScrollToTop;
